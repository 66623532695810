import { styled } from "@mui/material";

export default styled("div")`

    width: 266px;
    height: 553px;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top:20px;
    
    .phone {
        margin-top: 10px;
        width: 100%;
        height: 553px;
        display: flex;
        position: relative;

        img.phone-image {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        .notch {
            z-index: 2;
            position: absolute;
            margin-top: 10px;
            margin-left: 11px;
            padding: 0 ;
            height: 36px;
            width: 255px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: transparent;
            justify-content: space-between;
            background-color: transparent;
            border-top-right-radius: 37px;
            border-top-left-radius: 37px;

            .island {
                top: 12px;
                left: 50%;
                width: 75px;
                height: 20px;
                position: absolute;
                transform: translate(-50%, -50%);
                background-color: #666666;
                border-radius: 37px;
            }

            .clock {
                font-size: 12px;
                color: ${({ theme }) => theme.palette.text.secondary};
                font-weight: bold;
                margin-inline-start: 15px;
                margin-bottom: 10px;
            }
        }

        .phone-ui-content {
            z-index: 1;
            position: absolute;
            top: 6px;
            left: 8px;
            height: 541px;
            width: 250px;
            border-radius: 37px;
            overflow-x: hidden;
            background-color: transparent;
            margin-bottom: 20em;
            background-color: ${({ theme }) => theme.palette.background.card};
            overflow-x: hidden;
        }
    }

`;
