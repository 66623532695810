import { styled } from "@mui/material";

export const SpotlightStyle = styled("div")`
    margin: 0;
    padding: 0 5px;
    height: 365px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.palette.background.card};
   
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-tile {
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.palette.background.secondaryCard};
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        display: inline-flex;
    }

`;

export const ContinueStyle = styled("div")`

    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    background-color: ${({ theme }) => theme.palette.background.card};
    padding: 10px 5px;
    box-sizing: content-box;
    overflow-y: hidden;
    overflow-x: auto;

    .type-ttile {
        width: 100%;
        direction: ltr;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0px;
        line-height: 20px;
        height: 20px;

    }

    .content-wrapper {
        display: flex;
        width: 500px;
        height: 130px;
        flex-direction: row;
        overflow-y: hidden;
        padding: 10px 0;
        box-sizing: content-box;
    }

    .content-tile {
        margin-inline-end: 12px;
        max-width: 200px;
        width: 100%;
        height: 110px;
        background-color: ${({ theme }) => theme.palette.background.secondaryCard};
        border-radius: 16px;
        position: relative;

        .sample-text {
            max-width: 100%;
            width: 100%;
            padding: 0;
            position: absolute;
            bottom: -20px;
            left: 0;
            display: flex;
            justify-content: space-between;

            .label {
                font-size: 13px;
            }

            .time {
                font-size: 11px;
                color: ${({ theme }) => theme.palette.text.secondary};
            }
        }
    }

`;

export const HorizontalStyle = styled("div")`

    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    background-color: ${({ theme }) => theme.palette.background.card};
    padding: 10px 5px;
    box-sizing: content-box;
    overflow-y: hidden;
    overflow-x: auto;

    .type-ttile {
        width: 100%;
        direction: ltr;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0px;
        line-height: 20px;
        height: 20px;

    }

    .content-wrapper {
        display: flex;
        width: 300px;
        height: 130px;
        flex-direction: row;
        overflow-y: hidden;
        padding: 10px 0;
        box-sizing: content-box;
    }

    .content-tile {
        margin-inline-end: 12px;
        min-width: 80px;
        height: 110px;
        background-color: ${({ theme }) => theme.palette.background.secondaryCard};
        border-radius: 16px;
        position: relative;

        .sample-text {
            max-width: 100%;
            width: 100%;
            padding: 0;
            position: absolute;
            bottom: -20px;
            left: 0;
            display: flex;
            justify-content: space-between;

            .label {
                font-size: 8px;
            }

            .time {
                font-size: 6px;
                color: ${({ theme }) => theme.palette.text.secondary};
            }
        }
    }

`;

export const VerticalStyle = styled("div")`

    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: ${({ theme }) => theme.palette.background.card};
    padding: 10px 5px;
    box-sizing: content-box;
    overflow-x: hidden;

    .type-ttile {
        width: 100%;
        direction: ltr;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0px;
        line-height: 20px;
        height: 20px;

    }

    .content-wrapper {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        overflow-x: hidden;
        padding: 10px 0;
        box-sizing: content-box;

        .content-tile {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background-color: ${({ theme }) => theme.palette.background.secondaryCard};
            margin-bottom: 8px;
            height: 100px;
            padding: 10px;

            .image {
                height: 100%;
                width: 100px;
                background-color: ${({ theme }) => theme.palette.background.card};
                border-radius: 20px;
                margin-inline-end: 10px;
            }

            .texts {
                padding: 10px 0;
                height: 100%;
                display: flex;
                box-sizing: border-box;
                align-items: stretch;
                flex-direction: column;
                justify-content: space-between;

                .sample-text {
                    display: flex;
                    font-size: 12px;flex-direction: column;
                    .label {
                        font-size: 12px;
                    }

                    .time {
                        font-size: 10px;
                        color: ${({ theme }) => theme.palette.text.secondary};
                    }
                }

                .tags {
                    display: flex;
                    font-size: 9px;
                    color: ${({ theme }) => theme.palette.text.secondary};
                }
            }
        }
    }


`;
