import * as yup from "yup";

import { PlansDuration } from "@/constants/plansDuration";
import { EDuration } from "@/api/types/plans";

export default yup
    .object()
    .shape({
        isActive: yup.boolean().required("Active status is required"),
        price: yup.number().positive("Price must be a positive number").required("Price is required"),
        en_title: yup
            .string()
            .min(2, "English title must be at least 2 characters")
            .max(80, "English title must be at most 80 characters")
            .required("English title is required"),
        ar_title: yup
            .string()
            .min(2, "Arabic title must be at least 2 characters")
            .max(80, "Arabic title must be at most 80 characters")
            .required("Arabic title is required"),
        en_description: yup
            .string()
            .max(600, "English description must be at most 600 characters")
            .required("English description is required"),
        ar_description: yup
            .string()
            .max(600, "Arabic description must be at most 600 characters")
            .required("Arabic description is required"),
        ml_features: yup
            .array(
                yup.object({
                    en: yup.string().required("English feature is required"),
                    ar: yup.string().required("Arabic feature is required"),
                })
            )
            .min(1, "At least 1 feature is required")
            .required("Features are required"),
        hasTrial: yup.boolean(),
        showInWeb: yup.boolean(),
        showInApp: yup.boolean(),
        googleProductId: yup.string().when("showInApp", {
            is: (value: boolean) => value,
            then: () => yup.string().required("Google Product Id is required"),
        }),
        appleProductId: yup.string().when("showInApp", {
            is: (value: boolean) => value,
            then: () => yup.string().required("Apple Product Id is required"),
        }),
        currency: yup
            .string()
            .oneOf(["KWD", "BHD", "OMR", "AED", "SAR", "USD"], "Invalid currency")
            .required("Currency is required"),
        duration: yup.mixed<EDuration>().oneOf(PlansDuration).required("Duration is Required"),
        trialDuration: yup
            .mixed<EDuration>()
            .oneOf(PlansDuration)
            .when("hasTrial", {
                is: (value: boolean) => !!value,
                then: () =>
                    yup
                        .mixed<EDuration>()
                        .oneOf(PlansDuration)
                        .required("Trial Period is required when Has Trial is selected"),
            }),
    })
    .test(
        "at-least-one-checked",
        "At least one of 'Show in Web' or 'Show in App' must be selected",
        (values) => values.showInWeb || values.showInApp
    );
