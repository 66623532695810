import { number, object, string } from "yup";

import { UI_SECTION } from "@/constants/enums/home-page-ui";

export default object().shape({
    type: string().required("Type is required"),
    itemCount: number().positive("Enter Positive number").required("itemCount is required"),
    tag: number().when("type", {
        is: (value: string) => value === UI_SECTION.CONTINUE,
        then: () => number(),
        otherwise: () => number().positive().required("You Must Select A Tag"),
    }),
    en_title: string().max(80, "Enlish title must be less than 80 characters"),
    ar_title: string().max(80, "Arabic title must be less than 80 characters"),
});
