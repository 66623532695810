import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    padding: .5em 0;
    flex-direction: column;

    
   .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0px;
        padding: 10px;
    }

    .vertical-list {

        padding: 0 10px;
        display: flex;
        flex-direction: column;

        .content-tile {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 10px;
            overflow: hidden;
            width: 100%;

            img {
                width: 120px;
                height: 100px;
                border-radius: 20px;
                background-color: #444;
            }

            .info {
                padding: 10px;
                position: relative;

                .content-type {
                    font-size: 10px;
                    color: #777;
                }

                .name {
                    font-size: 11px;
                }

                .genres {
                    bottom: 0;
                    position: absolute;
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 19.2px;
                    letter-spacing: 0px;

                    display: flex;
                    flex-direction: row;

                    span {
                        position: relative;
                        margin-inline-end: 15px;

                        &:last-of-type {
                            &::after {
                                display: none;
                            }
                        }

                        &::after {
                            content:"";
                            position: absolute;
                            right: -10px;
                            width: 5px;
                            height: 5px;
                            border-radius: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: #0F62F3;
                        }
                    }
            }
        }
    }
}
   
   

`;
