import { Routes as RoutesWrapper, Route, Navigate } from "react-router-dom";
import NotFound from "src/pages/NotFound";
import Forbidden from "src/pages/Forbidden";
import AuthLayout from "src/layouts/AuthLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

import ROUTE_CONSTANTS from "../constants/route-constants";

import {
    ProtectedUserList,
    ProtectedUserFormUpdate,
    ProtectedUserFormCreate,
    ProtectedCustomerList,
    ProtectedCustomerFormUpdate,
    ProtectedCustomerFormDetails,
} from "./userManagementRoutes";
import { ProtectedUIHomePage, ProtectedUIFormUpdate, ProtectedUIFormCreate } from "./uiManagementRoutes";
import {
    ProtectedCountriesList,
    ProtectedCountriesDetails,
    ProtectedStaticList,
    ProtectedStaticFormUpdate,
    ProtectedStaticFormCreate,
    ProtectedPlanList,
    ProtectedPlanFormUpdate,
    ProtectedPlanFormCreate,
    ProtectedPlanFormDetails,
} from "./settingsRoute";
import {
    ProtectedVideoList,
    ProtectedVideoUpdate,
    ProtectedVideoCreate,
    ProtectedVideoDetails,
    ProtectedImageList,
    ProtectedImageForm,
    ProtectedAvatarList,
    ProtectedAvatarCreate,
} from "./mediaManagement";
import {
    ProtectedContentList,
    ProtectedAddMovieFrom,
    ProtectedUpdateMovieFrom,
    ProtectedAddSeries,
    ProtectedUpdateSeries,
    ProtectedContentShow,
    ProtectedTagList,
    ProtectedTagFormAdd,
    ProtectedTagFormUpdate,
    ProtectedGenreList,
    ProtectedGenreFormUpdate,
    ProtectedGenreFormAdd,
    ProtectedPublisher,
    ProtectedPublisherAdd,
    ProtectedPublisherEdit,
} from "./contentManagementRoutes";

import Dashboard from "@/pages/Dashboard";
import MediaManagement from "@/pages/MediaManagement";
import ContentManagement from "@/pages/ContentManagement";
import UserManagement from "@/pages/UserManagement";
import AppUiManagement from "@/pages/AppUI";
import Settings from "@/pages/Settings";
import Countries from "@/pages/Settings/Countries";
import PlansOutLet from "@/pages/Settings/Plans/outlet";
import Login from "@/pages/auth/Login";
import SetPassword from "@/pages/auth/SetPassword";
import ForgetPassword from "@/pages/auth/ForgetPassword";

const Routes: React.FC = () => {
    return (
        <RoutesWrapper>
            <Route element={<AuthLayout />} path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE}>
                <Route element={<Navigate replace to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />} path="" />
                <Route element={<Login />} path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />
                <Route element={<SetPassword />} path={ROUTE_CONSTANTS.AUTH.SET_PASSWORD.RELATIVE} />
                <Route element={<ForgetPassword />} path={ROUTE_CONSTANTS.AUTH.FORGET_PASSWORD.RELATIVE} />
            </Route>
            <Route element={<DashboardLayout />} path={ROUTE_CONSTANTS.ROOT.RELATIVE}>
                <Route element={<Navigate replace to={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} />} path="" />
                <Route element={<Dashboard />} path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} />
                {/* User Management */}
                <Route element={<UserManagement />} path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROOT.RELATIVE}>
                    <Route element={<Navigate replace to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE} />} path="" />
                    <Route element={<ProtectedUserList />} path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedUserFormUpdate />}
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedUserFormCreate />}
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedCustomerList />}
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedCustomerFormUpdate />}
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedCustomerFormDetails />}
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.SHOW.ROOT.RELATIVE}
                    />
                </Route>
                {/* Content Management */}
                <Route element={<ContentManagement />} path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.ROOT.RELATIVE}>
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route
                        element={<ProtectedContentList />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedAddMovieFrom />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_MOVIE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedAddSeries />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_SERIES.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedUpdateSeries />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_SERIES.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedUpdateMovieFrom />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_MOVIE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedContentShow />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW.ROOT.RELATIVE}
                    />
                    <Route element={<ProtectedTagList />} path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedTagFormAdd />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedTagFormUpdate />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route element={<ProtectedGenreList />} path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedGenreFormUpdate />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedGenreFormAdd />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedPublisher />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedPublisherAdd />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_PUBLISHER.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedPublisherEdit />}
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_PUBLISHER.ROOT.RELATIVE}
                    />
                </Route>
                {/* Media Management */}
                <Route element={<MediaManagement />} path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.ROOT.RELATIVE}>
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route element={<ProtectedVideoList />} path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedVideoUpdate />}
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedVideoCreate />}
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedVideoDetails />}
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.SHOW.ROOT.RELATIVE}
                    />
                    <Route element={<ProtectedImageList />} path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedImageForm />}
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route element={<ProtectedAvatarList />} path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedAvatarCreate />}
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ADD_NEW.ROOT.RELATIVE}
                    />
                </Route>
                {/* UI Management */}
                <Route element={<AppUiManagement />} path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.ROOT.RELATIVE}>
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route element={<ProtectedUIHomePage />} path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE} />
                    <Route
                        element={<ProtectedUIFormUpdate />}
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedUIFormCreate />}
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW.ROOT.RELATIVE}
                    />
                </Route>
                {/* Settings Management */}
                <Route element={<Settings />} path={ROUTE_CONSTANTS.SETTINGS.ROOT.RELATIVE}>
                    <Route element={<Navigate replace to={ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.ABSOLUTE} />} path="" />
                    <Route element={<ProtectedStaticList />} path={ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.RELATIVE} />
                    <Route
                        element={<ProtectedStaticFormUpdate />}
                        path={ROUTE_CONSTANTS.SETTINGS.STATIC.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={<ProtectedStaticFormCreate />}
                        path={ROUTE_CONSTANTS.SETTINGS.STATIC.ADD_NEW.ROOT.RELATIVE}
                    />
                    {/* Countries */}
                    <Route element={<Countries />} path={ROUTE_CONSTANTS.SETTINGS.COUNTRIES.ROOT.RELATIVE}>
                        <Route
                            element={<ProtectedCountriesList />}
                            path={ROUTE_CONSTANTS.SETTINGS.COUNTRIES.ROOT.ABSOLUTE}
                        />
                        <Route
                            element={<ProtectedCountriesDetails />}
                            path={ROUTE_CONSTANTS.SETTINGS.COUNTRIES.CONTENT.DETAILS.ROUTE.ABSOLUTE}
                        />
                    </Route>

                    {/* plans */}
                    <Route element={<PlansOutLet />} path={ROUTE_CONSTANTS.SETTINGS.PLANS.ROOT.RELATIVE}>
                        <Route element={<ProtectedPlanList />} path={ROUTE_CONSTANTS.SETTINGS.PLANS.ROOT.ABSOLUTE} />
                        <Route
                            element={<ProtectedPlanFormCreate />}
                            path={ROUTE_CONSTANTS.SETTINGS.PLANS.ADD_NEW.ABSOLUTE}
                        />
                        <Route
                            element={<ProtectedPlanFormDetails />}
                            path={ROUTE_CONSTANTS.SETTINGS.PLANS.DETAILS.ROUTE.ABSOLUTE}
                        />
                        <Route
                            element={<ProtectedPlanFormUpdate />}
                            path={ROUTE_CONSTANTS.SETTINGS.PLANS.EDIT.ROUTE.ABSOLUTE}
                        />
                    </Route>
                </Route>
            </Route>
            <Route element={<NotFound />} path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} />
            <Route element={<NotFound />} path={ROUTE_CONSTANTS.NOT_FOUND.RELATIVE} />
            <Route element={<Forbidden />} path={ROUTE_CONSTANTS.FORBIDDEN.RELATIVE} />
        </RoutesWrapper>
    );
};

export default Routes;
