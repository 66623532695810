import { useEffect, useState } from "react";
import { Box, Grid, Button, FormControl, FormHelperText, TextField, IconButton } from "@mui/material";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import { FeatureComponentStyle } from "./style";

import { TextFieldSchema } from "@/types/form-generator-schema-type";

interface IFeatures {
    field: ControllerRenderProps<FieldValues, string>;
    className?: string;
    errorMessage: string;
    props?: TextFieldSchema["props"];
}

const availableLangs = [
    { name: "en", placeholder: "En Feature" },
    { name: "ar", placeholder: "Ar Feature" },
];

const defaultValues: { en: string, ar: string } = { en: "", ar: "" };

export default function Features(props: IFeatures) {
    const [edit, setEdit] = useState<number | null>(null);
    const [values, setValues] = useState<{ en: string, ar: string }[]>([]);
    const [inputs, setInputs] = useState(defaultValues);
    const [error, setError] = useState("");
    const { field, className, errorMessage } = props;
    const { name, onChange, onBlur, ref, value } = field;

    useEffect(() => setValues(value), [value]);

    const handleEdit = (index: number) => {
        setEdit(index);
        setInputs(values[index]);
    };

    const handleClick = () => {
        if (inputs.en === "" || inputs.ar === "") {
            setError("these fields can not be empty");

            return;
        } else {
            setError("");
        }

        setValues((prev) => {
            const newList = [...prev];

            if (edit === 0 || edit) {
                newList[edit] = inputs;
            } else {
                newList.push(inputs);
            }

            onChange({ target: { name, value: newList } });

            return newList;
        });
        setInputs(defaultValues);
        setEdit(null);
    };

    const handleDelete = (index: number) => {
        setValues((prevValues) => {
            const prevVal = [...prevValues];

            prevVal.splice(index, 1);
            onChange({ target: { name, value: prevVal } });

            return prevVal;
        });
    };

    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        const name = e.target.name;

        setInputs((prev) => ({ ...prev, [name]: val }));
    };

    return (
        <FeatureComponentStyle>
            <FormControl fullWidth className={className} margin="normal" variant="outlined">
                <Box className="content">
                    <Grid container alignItems="center" spacing={1}>
                        {availableLangs.map((item) => {
                            const { name: lang, placeholder } = item;

                            return (
                                <Grid key={lang} item md={5} xs={12}>
                                    <TextField
                                        ref={ref}
                                        fullWidth
                                        multiline
                                        label={placeholder}
                                        maxRows={5}
                                        minRows={3}
                                        name={lang}
                                        placeholder={placeholder}
                                        sx={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
                                        value={inputs[lang as "ar" | "en"]}
                                        variant="outlined"
                                        onBlur={onBlur}
                                        onChange={handleLocalChange}
                                        {...props}
                                    />
                                </Grid>
                            );
                        })}
                        <Grid item md={2} sx={{ alignItems: "center" }} xs={12}>
                            <Button className="add-btn" color="primary" variant="contained" onClick={handleClick}>
                                {edit || edit === 0 ? "Update" : "Add"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {error && <FormHelperText error>{error}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12}>
                            {errorMessage ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
            <Box className="features-list">
                {values.map((item, index) => {
                    const { en, ar } = item;

                    return (
                        <Box key={en} className="features-item">
                            <Box className="content">
                                <p className="en text">
                                    <b>En:</b> <span>{en}</span>
                                </p>
                                <p className="ar text">
                                    <b>Ar: </b>
                                    <span>{ar}</span>
                                </p>
                            </Box>
                            <Box className="actions">
                                <IconButton className="icon-btn" onClick={() => handleEdit(index)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton className="icon-btn" onClick={() => handleDelete(index)}>
                                    <DeleteOutlineOutlined />
                                </IconButton>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </FeatureComponentStyle>
    );
}
