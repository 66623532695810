/* eslint-disable no-unused-vars */
export enum CONTENT_TYPE {
    MOVIE = 1,
    SERIES = 2,
    SESSION = 3,
    EPISODE = 4,
}

export const CONTENT_TYPE_TEXT: { [key: string]: string } = {
    "1": "Movies",
    "2": "Series",
    "3": "Session",
    "4": "Episode",
};
