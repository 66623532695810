import { Box, Typography } from "@mui/material";

import { VerticalStyle } from "./style";

export default function Vertical() {
    return (
        <VerticalStyle className="Vertical">
            <Typography className="type-ttile">Vertical (Latest Series)</Typography>
            <Box className="content-wrapper">
                <Box className="content-tile">
                    <Box className="image" />
                    <Box className="texts">
                        <Typography className="sample-text">
                            <span className="label">Label</span>
                            <span className="time">40:43 mins Left</span>
                        </Typography>
                        <Typography className="tags">Genres</Typography>
                    </Box>
                </Box>
                <Box className="content-tile">
                    <Box className="image" />
                    <Box className="texts">
                        <Typography className="sample-text">
                            <span className="label">Label</span>
                            <span className="time">40:43 mins Left</span>
                        </Typography>
                        <Typography className="tags">Genres</Typography>
                    </Box>
                </Box>
                <Box className="content-tile">
                    <Box className="image" />
                    <Box className="texts">
                        <Typography className="sample-text">
                            <span className="label">Label</span>
                            <span className="time">40:43 mins Left</span>
                        </Typography>
                        <Typography className="tags">Genres</Typography>
                    </Box>
                </Box>
            </Box>
        </VerticalStyle>
    );
}
