import { ISection } from "@/api/types/home-ui";

const sectionDataKey = Symbol("section");

export type TSectionData = { [sectionDataKey]: true, sectionId: ISection["uuid"] };

export function getSectionData(section: ISection): TSectionData {
    return { [sectionDataKey]: true, sectionId: section.uuid };
}

export function isSectionData(data: Record<string | symbol, unknown>): data is TSectionData {
    return data[sectionDataKey] === true;
}
