import { Box, Typography } from "@mui/material";

import { SpotlightStyle } from "./style";

export default function Spotlight() {
    return (
        <SpotlightStyle className="spotlight">
            <Box className="content-tile">
                <Typography className="type-title">Spotlight</Typography>
            </Box>
        </SpotlightStyle>
    );
}
