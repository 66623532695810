import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Preview from "@/pages/AppUI/Home/partials/preview/phoneContent";

interface IModalPreview {
    id: number;
    open: boolean;
    onClose: () => void;
}

export default function PreviewModal({ id, open, onClose }: IModalPreview) {
    return (
        <Dialog
            aria-describedby={`transition-modal-description`}
            aria-labelledby={`transition-modal-title`}
            open={open}
            onClose={onClose}
        >
            <DialogTitle style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Preview id={id} mode={2} />
            </DialogContent>
        </Dialog>
    );
}
