import { Box, Typography } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars";

import { ContinueStyle } from "./style";

export default function Continue() {
    return (
        <ContinueStyle className="continue-watching">
            <Typography className="type-ttile">Continue Watching</Typography>
            <Scrollbars>
                <Box className="content-wrapper">
                    <Box className="content-tile">
                        <Typography className="sample-text">
                            <span className="label">Label</span>
                            <span className="time">40:43 mins Left</span>
                        </Typography>
                    </Box>
                    <Box className="content-tile">
                        <Typography className="sample-text">
                            <span className="label">Label</span>
                            <span className="time">40:43 mins Left</span>
                        </Typography>
                    </Box>
                </Box>
            </Scrollbars>
        </ContinueStyle>
    );
}
