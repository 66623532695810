import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import LiveContinueStyle from "./style";

import { IDesginItem } from "@/api/types/home-ui";

export default function LiveContinue({ data }: { data: IDesginItem[] }) {
    return (
        <LiveContinueStyle className="continue-watching-wrapper">
            <Typography className="title">Continue Watching</Typography>
            <Box className="continue-list">
                <Swiper
                    className="mySwiper"
                    modules={[Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                >
                    {data.map((item) => {
                        const { thumbnailLandscape, ml_name, length } = item;

                        return (
                            <SwiperSlide key={item.id}>
                                <Box className="continue-box">
                                    <img alt={ml_name?.en} src={thumbnailLandscape?.url} />
                                    <Typography className="text">
                                        <span className="name">{ml_name?.en}</span>
                                        <span className="length">{length}</span>
                                    </Typography>
                                </Box>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
        </LiveContinueStyle>
    );
}
