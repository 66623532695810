import { styled } from "@mui/material";

export default styled("div")`

    padding: .5em 0;

   .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 0px;
        padding: 10px;
    }

    .continue-list {
        display: flex;
        flex-direction: row;
        padding: 0 0 0 10px;
        cursor: grab;

        .swiper-pagination  {
            display: none;
        }

        .swiper-slide {
            width: 80%;
        }
    }

    .continue-box {
        width: 200px;
        height: auto;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        margin-inline-end: 10px;

        img {
            width: 100%;
            height: 110px;
            border-radius: 20px;
            background-color: #444;
        }

        .text {
            margin-top: 10px;
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .name {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                line-height: 19.2px;
                letter-spacing: 0px;
            }

            .length {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0%;
                text-align: center;
                color: #888;
            }
        }
    }

`;
