import { styled } from "@mui/material";

export default styled("div")`

    width: 276px;
    height: 626px;
    display: flex;
    position: relative;
    flex-direction: column;


    .action-button {
      
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            margin-inline: 5px;
        }
    }
`;
