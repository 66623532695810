/* eslint-disable no-unused-vars */
import { Meta, AvailableCurrency, AvailableLang } from ".";

export interface MultiLang {
    en: string;
    ar: string;
}

export enum EDuration {
    OneWeek = "One Week",
    OneMonth = "One Month",
    // TwoMonth = "Two Months",
    // ThreeMonth = "Tree Months",
    // SixMonth = "Six Months",
    // OneYear = "One Year",
}

export interface BasePlan {
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    currency: AvailableCurrency;
    duration: EDuration;
    trialDuration?: EDuration;
    price: number;
    hasTrial?: boolean;
    isActive: boolean;
    showInWeb?: boolean;
    showInApp?: boolean;
    appleProductId?: string;
    googleProductId?: string;
}

export interface IPlan extends BasePlan {
    ml_features: MultiLang[];
    ml_description: MultiLang;
    ml_title: MultiLang;
}

export interface TCheckout {
    subtotal: 0;
    discount: 0;
    total: 0;
}

export interface IRequestParams {
    currency?: AvailableCurrency;
    lang?: AvailableLang;
    id: number;
}

export type IRequestPramsNoId = Omit<IRequestParams, "id">;

// CHECKOUT
export interface IPlanCheckoutRequestParams extends IRequestParams {}
export interface IPlanCheckoutReponse {
    data: IPlan;
    meta: Meta;
}

// GET BY ID
export interface IGetPlanRequestParams extends IRequestParams {}
export interface IGetPlanReponse extends Required<IPlanCheckoutReponse> {}

// UPDATE
export interface IUpdatePlanRequestParams extends IRequestParams {}
export interface IUpdatePlanReponse extends IPlanCheckoutReponse {}
export interface IUpdatePlanRequestBody extends IPlan {}

// DELETE
export interface IDeletePlanRequestParams extends IRequestParams {}
export interface IDeleteResponse {
    data: {
        result: string,
    };
    meta: Meta;
}

// CREATE
export interface ICreatePlanRequestParams extends IRequestPramsNoId {}
export interface ICreatePlanRequestBody extends IPlan {}
export interface ICreatePlanResponse extends IPlanCheckoutReponse {}

// GET LIST
export interface IGetPlansRequestParams extends IRequestPramsNoId {}
export interface IGetPlansResponse {
    data: {
        items: Required<IPlan>[],
    };
    meta: Meta;
}
