import { FilterBody } from "../types";

import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { ITagCreatePayload, ITagListResponse, ITagUpdatePayload, IGetTagListResponse } from "@/api/types/tag";
import { serializeObject } from "@/utils/serializer";

export const tagService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<ITagListResponse>>>(API_ROUTES.TAG.LIST, params);
    },
    create(data: ITagCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.TAG.CREATE, data);
    },
    update(id: number, data: ITagUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.TAG.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<ITableResponse<ITagListResponse>>>(API_ROUTES.TAG.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<ITagListResponse>>(API_ROUTES.TAG.SHOW(id));
    },
};

export const GetTags = async (params: FilterBody) => {
    const result = await AxiosInstance.get<IGetTagListResponse>(`${API_ROUTES.TAG.LIST}?${serializeObject(params)}`);

    return result.data;
};
