import { Box, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import UIForm from "../partials/form";
import Preview from "../partials/preview";

import CreateHomeUIStyle from "./style";

import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";
import { useHomeUiActions } from "@/context/home-ui";

export default function CreateHomeUI() {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const listAccess = useCheckPermissionAccess(PagesAccess.UI_HOME, OperationAccess.LIST);
    const { clearToDefault } = useHomeUiActions();
    const [UI_ID, setUI_ID] = useState(Number(id));

    // clear everything for next time visit
    useEffect(() => {
        return () => {
            clearToDefault();
        };
    }, [clearToDefault]);

    return (
        <CreateHomeUIStyle className="create-home-ui">
            <DashboardHeader
                title={`${isEditing ? "Update" : "Create New"} Home page UI`}
                titleSuffix={
                    listAccess && (
                        <Button component={Link} to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE}>
                            Back To Home List
                        </Button>
                    )
                }
            />
            <Box className="content">
                <Box className="form-wrapper">
                    <UIForm onUICreated={(id) => setUI_ID(id)} />
                </Box>
                <Box className="preview-wrapper">
                    <Box className="preview-container">
                        <Preview id={UI_ID} />
                    </Box>
                </Box>
            </Box>
        </CreateHomeUIStyle>
    );
}
