import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PlanFormStyle from "./style";
import Validation from "./validation";
import FormFeatures from "./features";

import { EDuration, MultiLang } from "@/api/types/plans";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormCheckbox from "@/components/kit/FormGenerator/FormCheckbox";
import FormSelect from "@/components/kit/FormGenerator/FormSelect";
import { usePlanById } from "@/api/hooks/usePlans";
import { AvailableCurrency } from "@/api/types";
import { PlansDuration } from "@/constants/plansDuration";
// import { useGetCountries } from "@/api/hooks/useCountries";

export interface ICreatePlanForm {
    en_title: string;
    ar_title: string;
    en_description: string;
    ar_description: string;
    ml_features: MultiLang[];
    currency: AvailableCurrency;
    isActive: boolean;
    price: number;
    duration: EDuration;
    trialDuration?: EDuration;
    googleProductId?: string;
    appleProductId?: string;
    hasTrial?: boolean;
    showInApp?: boolean;
    showInWeb?: boolean;
}

const defaultValues: ICreatePlanForm = {
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    ml_features: [],
    showInApp: false,
    showInWeb: true,
    isActive: true,
    hasTrial: false,
    googleProductId: "",
    appleProductId: "",
    currency: "USD",
    price: 0,
    duration: EDuration.OneMonth,
    trialDuration: EDuration.OneMonth,
};

interface IPlanForm {
    planId?: number;
    // eslint-disable-next-line no-unused-vars
    onSubmit: (fromData: ICreatePlanForm) => void;
}

export default function PlanForm({ onSubmit, planId }: IPlanForm) {
    const { data, isSuccess } = usePlanById({ id: Number(planId) });
    // const { countries } = useGetCountries();

    const content = data?.data;

    const {
        control,
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<ICreatePlanForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    const isShowInApp = watch("showInApp");
    const hasTrial = watch("hasTrial");

    useEffect(() => {
        if (!planId) reset(defaultValues);
    }, [reset, planId]);

    useEffect(() => {
        if (content && isSuccess) {
            const {
                price,
                duration,
                trialDuration,
                googleProductId,
                appleProductId,
                currency,
                hasTrial,
                isActive,
                showInApp,
                showInWeb,
                ml_features,
                ml_description,
                ml_title,
            } = content;

            const { ar: ar_title, en: en_title } = ml_description;
            const { ar: ar_description, en: en_description } = ml_title;

            reset({
                en_title,
                ar_title,
                en_description,
                ar_description,
                ml_features,
                showInApp,
                showInWeb,
                isActive,
                hasTrial,
                googleProductId,
                appleProductId,
                currency,
                price,
                duration,
                trialDuration,
            });
        }
    }, [reset, isSuccess, content]);

    // const Currencies = countries.map((item) => ({ value: item.currencyIso, label: item.currencyIso }));
    const Currencies = [{ value: "USD", label: "USD" }];

    return (
        <PlanFormStyle className="form-style">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            key="en_title"
                            control={control}
                            name="en_title"
                            render={({ field }) => (
                                <FormText
                                    errorMessage={errors["en_title"] as FieldError}
                                    field={{ ...field }}
                                    label="English Content Name"
                                    placeholder="Video File"
                                    props={{ required: true }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="ar_title"
                            control={control}
                            name="ar_title"
                            render={({ field }) => (
                                <FormText
                                    errorMessage={errors["ar_title"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Content Name"
                                    placeholder="Arabic title"
                                    props={{
                                        required: true,
                                        sx: {
                                            direction: "rtl",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="en_description"
                            control={control}
                            name="en_description"
                            render={({ field }) => (
                                <FormTextarea
                                    className="textarea"
                                    errorMessage={errors["en_description"] as FieldError}
                                    field={{ ...field }}
                                    label="English Description"
                                    placeholder="English Description"
                                    props={{ required: true }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="ar_description"
                            control={control}
                            name="ar_description"
                            render={({ field }) => (
                                <FormTextarea
                                    className="textarea"
                                    errorMessage={errors["ar_description"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Description"
                                    placeholder="Arabic Description"
                                    props={{
                                        required: true,
                                        sx: {
                                            direction: "rtl",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            key="ml_features"
                            control={control}
                            name="ml_features"
                            render={({ field }) => {
                                return (
                                    <FormFeatures
                                        className="input-filed features"
                                        errorMessage={(errors["ml_features"]?.message || "") as string}
                                        field={{ ...field }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="currency"
                            control={control}
                            name="currency"
                            render={({ field }) => (
                                <FormSelect
                                    className="input-filed"
                                    data={Currencies}
                                    errorMessage={errors["currency"] as FieldError}
                                    field={{ ...field }}
                                    label="Currency"
                                    placeholder="Select Items"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="price"
                            control={control}
                            name="price"
                            render={({ field }) => (
                                <FormText
                                    className="input-filed"
                                    errorMessage={errors["price"] as FieldError}
                                    field={{ ...field }}
                                    label="Price"
                                    placeholder="Video File"
                                    props={{ required: true }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            key="googleProductId"
                            control={control}
                            name="googleProductId"
                            render={({ field }) => (
                                <FormText
                                    className="input-filed"
                                    errorMessage={errors["googleProductId"] as FieldError}
                                    field={{ ...field }}
                                    label="Google Product Id"
                                    placeholder="Google Product Id"
                                    props={{ required: isShowInApp }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="appleProductId"
                            control={control}
                            name="appleProductId"
                            render={({ field }) => (
                                <FormText
                                    className="input-filed"
                                    errorMessage={errors["appleProductId"] as FieldError}
                                    field={{ ...field }}
                                    label="Apple Product Id"
                                    placeholder="Apple Product Id"
                                    props={{ required: isShowInApp }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            key="duration"
                            control={control}
                            name="duration"
                            render={({ field }) => (
                                <FormSelect
                                    className="input-filed"
                                    data={PlansDuration.map((item) => ({ label: item, value: item }))}
                                    errorMessage={errors["duration"] as FieldError}
                                    field={{ ...field, onChange: (e) => setValue("duration", e.target.value || 0) }}
                                    label="Plans Period"
                                    placeholder="Plans period duration"
                                    props={{ required: true, type: "number" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            key="trialDuration"
                            control={control}
                            name="trialDuration"
                            render={({ field }) => (
                                <FormSelect
                                    className="input-filed"
                                    data={PlansDuration.map((item) => ({ label: item, value: item }))}
                                    errorMessage={errors["trialDuration"] as FieldError}
                                    field={{ ...field, onChange: (e) => setValue("trialDuration", e.target.value || 0) }}
                                    label="Trial Period"
                                    placeholder="Trial period duration"
                                    props={{ required: hasTrial, type: "number" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Controller
                                    key="showInApp"
                                    control={control}
                                    name="showInApp"
                                    render={({ field }) => (
                                        <FormCheckbox
                                            className="input-filed"
                                            errorMessage={errors["showInApp"] as FieldError}
                                            field={{ ...field }}
                                            label="Show In App"
                                            placeholder="Show In App"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    key="showInWeb"
                                    control={control}
                                    name="showInWeb"
                                    render={({ field }) => (
                                        <FormCheckbox
                                            className="input-filed"
                                            errorMessage={errors["showInWeb"] as FieldError}
                                            field={{ ...field }}
                                            label="Show in Web"
                                            placeholder="Show in Web"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    key="hasTrial"
                                    control={control}
                                    name="hasTrial"
                                    render={({ field }) => (
                                        <FormCheckbox
                                            className="input-filed"
                                            errorMessage={errors["hasTrial"] as FieldError}
                                            field={{ ...field }}
                                            label="Has Trial"
                                            placeholder="Has Trial"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    key="isActive"
                                    control={control}
                                    name="isActive"
                                    render={({ field }) => (
                                        <FormCheckbox
                                            className="input-filed"
                                            errorMessage={errors["isActive"] as FieldError}
                                            field={{ ...field }}
                                            label="Is Active"
                                            placeholder="Is Active"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton color="primary" disabled={!isValid} type="submit" variant="contained">
                            {planId ? "Save" : "Create"}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </PlanFormStyle>
    );
}
