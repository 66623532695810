/* eslint-disable no-unused-vars */

import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";

import { ISection } from "@/api/types/home-ui";

interface IHomeUIState {
    sections: ISection[];
    currentSectionId: string;
    actions: {
        setSections: (value: ISection[]) => void,
        addSection: (value: Omit<ISection, "uuid">) => void,
        updateSection: (value: Omit<ISection, "uuid">) => void,
        removeSection: (value: string) => void,
        setCurrentSectionId: (value: string) => void,
        clearCurrentSectionId: () => void,
        clearToDefault: () => void,
    };
}

const useHomeUiStore = create<IHomeUIState>((set) => ({
    sections: [],
    currentSectionId: "",
    actions: {
        clearToDefault: () => {
            set((state) => {
                return {
                    ...state,
                    sections: [],
                    currentSectionId: "",
                };
            });
        },
        clearCurrentSectionId: () => {
            set((state) => {
                return {
                    ...state,
                    currentSectionId: "",
                };
            });
        },
        updateSection: (section) => {
            set((state) => {
                const sections = state.sections;
                const currentSectionId = state.currentSectionId;

                const updateIndex = sections.findIndex((section) => section.uuid === currentSectionId);

                sections[updateIndex] = { ...section, uuid: uuidv4() };

                return {
                    ...state,
                    sections: [...sections],
                };
            });
        },
        addSection: (value) => {
            set((state) => {
                return {
                    ...state,
                    sections: [...state.sections, { ...value, uuid: uuidv4() }],
                };
            });
        },
        setSections: (value) => {
            set((state) => {
                return {
                    ...state,
                    sections: value,
                };
            });
        },
        removeSection: (value) => {
            set((state) => {
                const newList = state.sections.filter((item) => item.uuid !== value);

                return {
                    ...state,
                    sections: newList,
                };
            });
        },
        setCurrentSectionId: (value) => {
            set((state) => {
                return {
                    ...state,
                    currentSectionId: value,
                };
            });
        },
    },
}));

export const useHomeUiActions = () => useHomeUiStore((state) => state.actions);

export const useHomeUiCurrentSectionId = () => useHomeUiStore((state) => state.currentSectionId);
export const useHomeUiSections = () => useHomeUiStore((state) => state.sections);

export const useCurrentSection = () => {
    const id = useHomeUiCurrentSectionId();
    const sections = useHomeUiSections();
    const currentSection = sections.filter((item) => item.uuid === id);

    return currentSection.length > 0 ? currentSection[0] : null;
};
