import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
        margin: 0;
        padding: 1em;
        box-sizing: content-box;
        display: grid;
        height: 100%;
        width: 100%;
        grid-template-columns: 500px 1fr;
        border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    }

    .form-wrapper {
        border-radius: 20px;
        background: #333;
        position: relative;
        max-width: 500px;
        background-color: ${({ theme }) => theme.palette.background.default};
        border: 1px solid ${({ theme }) => theme.palette.background.input};
    }

    .preview-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        .preview-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: ${({ theme }) => theme.shape.borderRadius}px;

            .preview {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
`;
