import { styled } from "@mui/material";

import Spotlight from "./liveComponents/spotlight";
import Continue from "./liveComponents/continue";
import Horizontal from "./liveComponents/horizontal";
import Vertical from "./liveComponents/vertical";

import { UI_SECTION } from "@/constants/enums/home-page-ui";
import { IDesginSection } from "@/api/types/home-ui";

export const SectionManagerStyle = styled("div")`
    height: 100%;
    width: 100%;
`;

export default function LiveSectionManager({ sections }: { sections: IDesginSection[] }) {
    return (
        <SectionManagerStyle className="section-management">
            {sections.map((section, index) => {
                if (section.type.toLowerCase() === UI_SECTION.SPOTLIGHT.toLowerCase()) {
                    return <Spotlight key={section.type + index} data={section.items[0]} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.CONTINUE.toLowerCase()) {
                    return <Continue key={section.type + index} data={section.items} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.HORIZONTAL.toLowerCase()) {
                    return <Horizontal key={section.type + index} data={section.items} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.VERTICAL.toLowerCase()) {
                    return <Vertical key={section.type + index} data={section.items} />;
                }

                return null;
            })}
        </SectionManagerStyle>
    );
}
