import { Box, Typography } from "@mui/material";

import LiveSpotlightStyle from "./style";

import { IDesginItem } from "@/api/types/home-ui";
import { CONTENT_TYPE_TEXT } from "@/constants/enums/content-type";

export default function LiveSpotlight({ data }: { data: IDesginItem }) {
    const { thumbnailPortrait, ml_description, genres, ml_name, typeId } = data;

    return (
        <LiveSpotlightStyle url={thumbnailPortrait?.url}>
            <Box className="info">
                <Typography className="content-type">{CONTENT_TYPE_TEXT[typeId.toString()]}</Typography>
                <Typography className="name">{ml_name?.en}</Typography>
                <Typography className="genres">
                    {genres.map((item) => (
                        <span key={item?.ml_title?.en}>{item?.ml_title?.en}</span>
                    ))}
                </Typography>
                <Typography className="description">{ml_description?.en?.slice(0, 30)}</Typography>
            </Box>
        </LiveSpotlightStyle>
    );
}
