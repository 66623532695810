import { styled } from "@mui/material";

export default styled("div")`

    /* background-color: ${({ bgColor }: { bgColor: string }) => bgColor}; */
    position: relative;
    min-height: 60px;

    &.dragging {
        opacity: .2;
        cursor: grabbing;
    }

    .section {
        
        color: ${({ theme }) => theme.palette.text.secondary};
        width: 100%;
        padding: 10px;
        display: flex;
        border-radius: 20px;
        min-height: inherit;
        flex-direction: row;
        align-items: center;
        background-color: ${({ theme }) => theme.palette.background.card};
        margin: 5px 0;
        justify-content: space-between;
        cursor: grab;

        .type {
            display: flex;
            flex-direction: row;

            .text {
                min-width: 80px;
                margin-inline: 5px;
            }
        }
        
        .actions {}
    }


`;

export const IndicatorStyle = styled("div")`

    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border: 3px dashed #555;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(255, 242, 219, .5);
    color: #555;
    
    .top {
        top:0;
        position: relative;
    }

    .bottom {
        bottom: 0;
    }

`;
