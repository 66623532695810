import { Link, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import PlanDetailsStyles, { TableOfContentStyles } from "./style";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useDeletePlan, usePlanById } from "@/api/hooks/usePlans";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export default function PlanDetails() {
    const { planId } = useParams();
    const PlanID = Number(planId);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const listAccess = useCheckPermissionAccess(PagesAccess.PLANS, OperationAccess.LIST);
    const deleteAccess = useCheckPermissionAccess(PagesAccess.PLANS, OperationAccess.DELETE);

    const { mutate: deletePlan } = useDeletePlan();
    const { data, isLoading } = usePlanById({ id: PlanID, currency: "KWD" });

    const handleDelete = () => {
        if (deleteAccess) {
            planId &&
                deletePlan(
                    { id: PlanID },
                    {
                        onSuccess: () => {
                            navigate(-1);
                        },
                    }
                );
        } else {
            enqueueSnackbar("You don't have the correct rights to Delete a plan.", { variant: "warning" });
        }
    };

    const handleClick = () => navigate(ROUTE_CONSTANTS.SETTINGS.PLANS.EDIT.ROOT(Number(planId)).ABSOLUTE);

    if (!listAccess) return <Typography>You Do Not Have Access To See The Details</Typography>;

    if (isLoading) return <Typography>Loading</Typography>;

    if (!data) return null;

    const {
        createdAt,
        updatedAt,
        appleProductId,
        currency,
        googleProductId,
        duration,
        price,
        hasTrial,
        isActive,
        showInApp,
        showInWeb,
    } = data?.data;

    const name = data?.data.ml_title.en || "";

    return (
        <PlanDetailsStyles>
            <Box className="plans-header">
                <Typography className="main-title">Details of Plan {name}</Typography>
                <Button component={Link} to={ROUTE_CONSTANTS.SETTINGS.PLANS.ROOT.ABSOLUTE}>
                    Back To Plan List
                </Button>
            </Box>
            <Divider />
            <Box className="create-plan-actions">
                <LoadingButton className="edit-btn" type="submit" variant="contained" onClick={handleClick}>
                    Edit Plan
                </LoadingButton>
                <LoadingButton className="delete-btn" color="error" type="submit" variant="contained" onClick={handleDelete}>
                    Delete Plan
                </LoadingButton>
            </Box>
            <Divider />
            <TableOfContentStyles className="show=plan-details">
                <div>
                    <span className="label">createdAt</span>
                    <span>{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</span>
                </div>
                <div>
                    <span className="label">updatedAt</span>
                    <span>{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                </div>
                <div>
                    <span className="label">currency</span>
                    <span>{currency}</span>
                </div>
                <div>
                    <span className="label">price</span>
                    <span>{price}</span>
                </div>
                <div>
                    <span className="label">duration</span>
                    <span>{duration}</span>
                </div>
                <div>
                    <span className="label">googleProductId</span>
                    <span>{googleProductId || "-"}</span>
                </div>
                <div>
                    <span className="label">appleProductId</span>
                    <span>{appleProductId || "-"}</span>
                </div>
                <div>
                    <span className="label">showInWeb</span>
                    <span>{showInWeb ? " Will show in Web " : " Will not show in Web"}</span>
                </div>
                <div>
                    <span className="label">showInApp</span>
                    <span>{showInApp ? " Will show in App " : " Will not show in App"}</span>
                </div>
                <div>
                    <span className="label">hasTrial</span>
                    <span>{hasTrial ? " With Trial " : "without Trial"}</span>
                </div>
                <div>
                    <span className="label">isActive</span>
                    <span>{isActive ? " Active " : "Not Active"}</span>
                </div>
            </TableOfContentStyles>
        </PlanDetailsStyles>
    );
}
