import { Scrollbars } from "react-custom-scrollbars";
import { Box } from "@mui/material";

import Clock from "../clock";
import SectionManager from "../sectionManager";
import LiveSectionManager from "../sectionManager/liveSectionManager";

import PhoneStyle from "./style";

import { useHomeDesign } from "@/api/hooks/useHomeUI";

export default function PhoneContent({ mode, id }: { id: number, mode: number }) {
    const { data: HomeUIData } = useHomeDesign(Number(id));

    return (
        <PhoneStyle className="phone-style">
            <Box className="phone">
                <img alt="phone" className="phone-image" src="/images/Home-UI/phone.png" />
                <Box className="notch">
                    <Box className="island" />
                    <Clock />
                </Box>
                <Box className="phone-ui-content">
                    <Scrollbars autoHide style={{ height: 541 }}>
                        {mode === 1 && <SectionManager />}
                        {mode === 2 && HomeUIData?.data.sections && (
                            <LiveSectionManager sections={HomeUIData?.data.sections} />
                        )}
                    </Scrollbars>
                </Box>
            </Box>
        </PhoneStyle>
    );
}
