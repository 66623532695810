import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { FilterBody } from "../types";
import { IUIUpdateBody, IUICreateBody } from "../types/home-ui";

import { errorCallback } from "@/api/hooks/error";
import { DeleteUI, GetUIs, GetUIById, UpdateUI, CreateUI, GetHomeDesgin } from "@/api/services/home-ui";

export function useGetUIs(params: FilterBody) {
    return useQuery({
        queryKey: ["HOME_UI", params],
        queryFn: () => GetUIs({ params }),
    });
}

export function useGetUIById(id: number) {
    return useQuery({
        queryKey: ["HOME_UI", id],
        queryFn: () => GetUIById(id),
    });
}

export function useHomeDesign(id: number) {
    return useQuery({
        enabled: !!id,
        queryKey: ["HOME_UI_DESIGN", id],
        queryFn: () => GetHomeDesgin(id),
    });
}

export function useDeleteUI() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => DeleteUI(id),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["HOME_UI"] });
            enqueueSnackbar("The UI Deleted Successfully", { variant: "success" });
        },
    });
}

export function useUpdateUI() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, body: Partial<IUIUpdateBody> }) => UpdateUI(id, body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["HOME_UI"] });
            queryClient.invalidateQueries({ queryKey: ["HOME_UI_DESIGN"] });
            enqueueSnackbar("The UI Updated Successfully", { variant: "success" });
        },
    });
}

export function useCreateUI() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body }: { body: IUICreateBody }) => CreateUI(body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["HOME_UI"] });
            enqueueSnackbar("The UI Created Successfully", { variant: "success" });
        },
    });
}
