import dayjs from "dayjs";

import { Data } from "./table/types";

import { IUI } from "@/api/types/home-ui";

export function createData(data: IUI): Data {
    const { id, name, category, updatedAt, isActive } = data;

    return {
        id,
        name,
        category,
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
        isActive,
    };
}
