import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { attachClosestEdge, type Edge, extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import SectionItemStyle from "./style";
import { getSectionData, isSectionData } from "./section-data";
import Indicator from "./indicator";

import { useHomeUiActions } from "@/context/home-ui";
import { SectionColors, SectionIcons } from "@/constants/enums/home-page-ui";
import { ISection } from "@/api/types/home-ui";

type TaskState =
    | {
          type: "idle",
      }
    | {
          type: "preview",
          container: HTMLElement,
      }
    | {
          type: "is-dragging",
      }
    | {
          type: "is-dragging-over",
          closestEdge: Edge | null,
      };

const idle: TaskState = { type: "idle" };

export default function SectionItem({ section }: { section: ISection }) {
    const ref = useRef(null);
    const [isDragging, setIsDraggingState] = useState(false);
    const [state, setState] = useState<TaskState>(idle);
    const { removeSection, setCurrentSectionId } = useHomeUiActions();

    const handleDelete = () => removeSection(section.uuid);
    const handleEdit = () => setCurrentSectionId(section.uuid);

    useEffect(() => {
        const element = ref.current;

        if (element) {
            return combine(
                draggable({
                    element,
                    getInitialData: () => {
                        return getSectionData(section);
                    },
                    onGenerateDragPreview({ nativeSetDragImage }) {
                        setCustomNativeDragPreview({
                            nativeSetDragImage,
                            getOffset: pointerOutsideOfPreview({
                                x: "-10px",
                                y: "-10px",
                            }),
                            render({ container }) {
                                setState({ type: "preview", container });
                            },
                        });
                    },
                    onDragStart: () => setIsDraggingState(true),
                    onDrop: () => setIsDraggingState(false),
                }),
                dropTargetForElements({
                    element,
                    canDrop({ source }) {
                        // not allowing dropping on yourself
                        if (source.element === element) {
                            return false;
                        }

                        // only allowing tasks to be dropped on me
                        return isSectionData(source.data);
                    },
                    getData({ input }) {
                        const data = getSectionData(section);

                        return attachClosestEdge(data, {
                            element,
                            input,
                            allowedEdges: ["top", "bottom"],
                        });
                    },
                    getIsSticky() {
                        return true;
                    },
                    onDragEnter({ self }) {
                        const closestEdge = extractClosestEdge(self.data);

                        setState({ type: "is-dragging-over", closestEdge });
                    },
                    onDrag({ self }) {
                        const closestEdge = extractClosestEdge(self.data);

                        // Only need to update react state if nothing has changed.
                        // Prevents re-rendering.
                        setState((current) => {
                            if (current.type === "is-dragging-over" && current.closestEdge === closestEdge) {
                                return current;
                            }

                            return { type: "is-dragging-over", closestEdge };
                        });
                    },
                    onDragLeave() {
                        setState(idle);
                    },
                    onDrop() {
                        setState(idle);
                    },
                })
            );
        }
    }, [section]);

    return (
        <>
            <SectionItemStyle
                ref={ref}
                bgColor={SectionColors[section.type as keyof typeof SectionColors]}
                className={`${isDragging ? "dragging" : ""}`}
            >
                {state.type === "is-dragging-over" && state.closestEdge === "top" ? (
                    <Indicator edge={state.closestEdge} />
                ) : null}
                <Box className="section">
                    <span className="type">
                        <span className="text">{section.type}</span>
                        <SvgIcon component={SectionIcons[section.type as keyof typeof SectionColors]} />
                    </span>
                    <span className="action">
                        <IconButton onClick={handleDelete}>
                            <Delete />
                        </IconButton>
                        <IconButton onClick={handleEdit}>
                            <Edit />
                        </IconButton>
                    </span>
                </Box>

                {state.type === "is-dragging-over" && state.closestEdge === "bottom" ? (
                    <Indicator edge={state.closestEdge} />
                ) : null}
            </SectionItemStyle>
            {state.type === "preview" ? createPortal(<SectionItem section={section} />, state.container) : null}
        </>
    );
}
