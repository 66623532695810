/* eslint-disable no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

import Validation from "./valiadtion";

import { UI_SECTION } from "@/constants/enums/home-page-ui";
import FormSelect from "@/components/kit/FormGenerator/FormSelect";
import FormText from "@/components/kit/FormGenerator/FormText";
import { useTags } from "@/api/hooks/useTags";
import { useCurrentSection, useHomeUiActions } from "@/context/home-ui";

export const UI_SECTIONS: UI_SECTION[] = [
    UI_SECTION.HORIZONTAL,
    UI_SECTION.SPOTLIGHT,
    UI_SECTION.CONTINUE,
    UI_SECTION.VERTICAL,
];

export interface IHomeUISectionForm {
    type: string;
    itemCount: number;
    en_title?: string;
    ar_title?: string;
    tag?: number | 0;
}

const defaultValues: IHomeUISectionForm = {
    type: "",
    tag: 0,
    en_title: "",
    ar_title: "",
    itemCount: 0,
};

export default function HomeUISection({
    isEntertainment,
    onSubmit,
}: {
    isEntertainment: boolean,
    onSubmit: (data: IHomeUISectionForm) => void,
}) {
    const { clearCurrentSectionId } = useHomeUiActions();
    const currentSection = useCurrentSection();
    const { data: dataTags, isLoading } = useTags({ skip: 0, take: 1000 });

    const isEditMode = !!currentSection;
    const tags = dataTags?.data?.items || [];
    const moduleData = UI_SECTIONS.map((item) => ({ label: item, value: item }));

    const TagList =
        tags.length > 0
            ? tags
                  .filter((item) => item.isEntertainment === isEntertainment)
                  .map((tag) => ({ value: tag.id, label: tag.ml_title.en }))
            : [];

    const {
        control,
        watch,
        reset,
        setValue,
        trigger,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IHomeUISectionForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    const type = watch("type");

    // this code is very important - it's like refresh button for react-hook-form, make it update and in sync in some cases
    useEffect(() => {
        if (isValid) void trigger();
    }, [trigger, isValid]);

    useEffect(() => {
        if (type === "spotLight") {
            setValue("en_title", "");
            setValue("ar_title", "");
        }
    }, [type, setValue]);

    useEffect(() => {
        if (type === "continue") {
            setValue("tag", 0);
        }
    }, [type, setValue]);

    // update values in edit mode
    useEffect(() => {
        if (currentSection) {
            const { ml_title, type, tag, itemCount } = currentSection;

            reset({
                ...currentSection,
                type: type,
                itemCount: itemCount,
                en_title: ml_title.en,
                ar_title: ml_title.ar,
                tag: tag,
            });
        }
    }, [reset, currentSection]);

    // clear data on unmount
    useEffect(() => {
        return () => {
            reset(defaultValues);
            clearCurrentSectionId();
        };
    }, [reset, clearCurrentSectionId]);

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    key="itemCount"
                    control={control}
                    name="itemCount"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["itemCount"] as FieldError}
                            field={{ ...field }}
                            label="Item Count"
                            placeholder="Enter a number"
                            props={{ required: true, type: "number" }}
                        />
                    )}
                />
                <Controller
                    key="Module"
                    control={control}
                    name="type"
                    render={({ field }) => (
                        <FormSelect
                            data={moduleData}
                            errorMessage={errors["type"] as FieldError}
                            field={{ ...field }}
                            label="Module"
                            placeholder="type"
                            props={{ required: true }}
                        />
                    )}
                />
                <Controller
                    key="tag"
                    control={control}
                    name="tag"
                    render={({ field }) => (
                        <FormSelect
                            data={TagList}
                            errorMessage={errors["tag"] as FieldError}
                            field={{ ...field }}
                            label="Tag"
                            placeholder="tag"
                            props={{
                                required: type.toLowerCase() !== UI_SECTION.CONTINUE.toLowerCase(),
                                disabled: isLoading || type.toLowerCase() === UI_SECTION.CONTINUE.toLowerCase(),
                            }}
                        />
                    )}
                />

                <Controller
                    key="en_title"
                    control={control}
                    name="en_title"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["en_title"] as FieldError}
                            field={{ ...field }}
                            label="English Title"
                            placeholder="Enter English Text"
                            props={{
                                disabled: type === "spotLight",
                            }}
                        />
                    )}
                />

                <Controller
                    key="ar_title"
                    control={control}
                    name="ar_title"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["ar_title"] as FieldError}
                            field={{ ...field }}
                            label="Arabic Title"
                            placeholder="Enter Arabic Text"
                            props={{ dir: "rtl", disabled: type === "spotLight" }}
                        />
                    )}
                />

                <br />
                <br />
                <LoadingButton className="loading-btn" disabled={!isValid} loading={false} type="submit" variant="contained">
                    {isEditMode ? "Update" : "Create"} a Section
                </LoadingButton>
            </form>
        </Box>
    );
}
