import { styled } from "@mui/material";

export default styled("div")`

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.background.card};
    border-radius: ${({ theme }) => theme.shape.borderRadius + 20}px;
    align-items: center;
    position: relative;
    z-index: 0;
    padding: 4px;

    &::before {
        z-index: 1;
        content: "";
        border-radius: ${({ theme }) => theme.shape.borderRadius + 20}px;
        position: absolute;
        height: 38px;
        width: calc(50% - 16PX);
        transition: all ease .2s;
    }

    &.selected {
        &::before  {
            left: 8px;
            transform: translateX(0%);
            background: linear-gradient(232.87deg, #0F62F3 -10%, #09398D 100%);
        }
    }

    &.notSelected {
        &::before  {
            left: 16px;
            transform: translateX(calc(100% + 6px));
            background: linear-gradient(232.87deg, #09398D -10%, #0F62F3 100%);
        }
    }

    .box {
        z-index: 2;
        position: relative;
        margin: 0;
        height: 44px;
        width: 100%;
        padding: 0;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        align-items: center;
        display: inline-flex;
        justify-items: center;
        box-sizing: border-box;
        justify-content: space-between;

        &.selected {

            .text {color:#fff}

            .image {
                transform: scale(0.95);
            }
        }

        .image {
            max-width: 40px;
            max-height: 40px;
            width: 40px;
            transform: scale(0.8);
            transition: all ease .5s;
        }
        
        .text {
            width: 100%;
            color: ${({ theme }) => theme.palette.text.primary};
            font-size: 1rem;
            text-align: center;
        }

    }


`;
