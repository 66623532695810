import { styled } from "@mui/material";

import Spotlight from "./offlineComponents/spotlight";
import Continue from "./offlineComponents/continue";
import Horizontal from "./offlineComponents/horizontal";
import Vertical from "./offlineComponents/vertical";

import { useHomeUiSections } from "@/context/home-ui";
import { UI_SECTION } from "@/constants/enums/home-page-ui";

export const SectionManagerStyle = styled("div")`
    overflow-x: hidden;
`;

export default function SectionManager() {
    const sections = useHomeUiSections();

    return (
        <SectionManagerStyle className="section-manager">
            {sections.map((section, index) => {
                if (section.type.toLowerCase() === UI_SECTION.SPOTLIGHT.toLowerCase()) {
                    return <Spotlight key={section.type + index} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.CONTINUE.toLowerCase()) {
                    return <Continue key={section.type + index} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.HORIZONTAL.toLowerCase()) {
                    return <Horizontal key={section.type + index} />;
                }

                if (section.type.toLowerCase() === UI_SECTION.VERTICAL.toLowerCase()) {
                    return <Vertical key={section.type + index} />;
                }

                return null;
            })}
        </SectionManagerStyle>
    );
}
