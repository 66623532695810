import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import UISectionForm, { IHomeUISectionForm } from "../UISectionForm";
import Sections from "../sectionList";
import CategorySelection from "../categorySelection";

import HomeUiFormStyle from "./style";

import { useHomeUiActions, useCurrentSection, useHomeUiSections } from "@/context/home-ui";
import { useCreateUI, useGetUIById, useUpdateUI } from "@/api/hooks/useHomeUI";
import { UI_SECTION } from "@/constants/enums/home-page-ui";
import ROUTE_CONSTANTS from "@/constants/route-constants";
// import ROUTE_CONSTANTS from "@/constants/route-constants";

const category = [
    { value: 1, label: "Education" },
    { value: 2, label: "Entertainment" },
];

// eslint-disable-next-line no-unused-vars
export default function HomeIUForm({ onUICreated }: { onUICreated: (id: number) => void }) {
    const navigate = useNavigate();
    const { id: UI_ID } = useParams();
    const [open, setOpen] = useState(false);
    const [helpStatus, setHelpStatus] = useState(false);
    const [values, setValues] = useState({ category: 1, name: "" });
    const { data: UIData } = useGetUIById(Number(UI_ID));
    const { mutate: createUI } = useCreateUI();
    const { mutate: updateUI } = useUpdateUI();
    const { enqueueSnackbar } = useSnackbar();
    const { addSection, updateSection, clearToDefault, setSections } = useHomeUiActions();
    const currentSection = useCurrentSection();
    const sections = useHomeUiSections();

    const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

    const handleCloseHelpDialog = () => setHelpStatus(false);

    const handleSubmitSection = (data: IHomeUISectionForm) => {
        const { en_title, ar_title, tag, type, itemCount } = data;
        const payload = {
            tag: tag || 0,
            type,
            itemCount,
            ml_title: {
                en: en_title || "",
                ar: ar_title || "",
            },
        };

        if (currentSection) {
            updateSection(payload);
        } else {
            addSection(payload);
        }
        setOpen(false);
    };

    const handleSubmitUI = () => {
        const SpotlightIndex = sections.findIndex(
            (section) => section.type.toLowerCase() === UI_SECTION.SPOTLIGHT.toLowerCase()
        );

        const totalSpotlight = sections.filter(
            (section) => section.type.toLowerCase() === UI_SECTION.SPOTLIGHT.toLowerCase()
        );

        if (!values.name) {
            enqueueSnackbar("You should provide a name", { variant: "warning" });

            return;
        }

        if (SpotlightIndex < 0) {
            enqueueSnackbar("At least on Spotlight Section is required", { variant: "warning" });

            return;
        }

        if (sections[0].type.toLowerCase() !== UI_SECTION.SPOTLIGHT.toLowerCase()) {
            enqueueSnackbar("Spotlight Must be the first item in the list", { variant: "warning" });

            return;
        }

        if (totalSpotlight.length > 1) {
            enqueueSnackbar("You should have only one Spotlight in the list.", { variant: "warning" });

            return;
        }

        // normalizing
        const Sections = sections.map((item) => {
            if (item.type.toLowerCase() === UI_SECTION.SPOTLIGHT.toLowerCase()) {
                return {
                    ...item,
                    type: "spotLight",
                };
            }

            return {
                ...item,
                type: item.type.toLowerCase(),
            };
        });

        const body = {
            name: values.name,
            category: values.category === 1 ? "Education" : "Entertainment",
            sections: Sections,
        };

        if (UI_ID) {
            updateUI(
                {
                    body,
                    id: Number(UI_ID),
                },
                {
                    onSuccess: () => {
                        navigate(ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE);
                    },
                }
            );
        } else {
            createUI(
                {
                    body,
                },
                {
                    onSuccess: (result) => {
                        if (onUICreated) onUICreated(result.data.id);
                        navigate(ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE);
                    },
                }
            );
        }
    };

    // handle category
    const handleSelect = (num: number) => {
        setValues((prev) => ({ ...prev, category: num }));
        clearToDefault();
    };

    const handleHelp = () => setHelpStatus(true);

    const setDataInForms = useCallback(() => {
        if (UIData) {
            const data = UIData.data;
            const { name, category, sections } = data;

            setValues({ category: category.toLowerCase() === "education" ? 1 : 2, name });
            setSections(sections);
        }
    }, [setSections, UIData]);

    // show ui section form in edit mode
    useEffect(() => {
        if (currentSection) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [currentSection]);

    // set fetched data into states
    useEffect(() => {
        if (UIData) {
            setDataInForms();
        }
    }, [setDataInForms, UIData]);

    // bring back fetched data if user switched back to its category
    useEffect(() => {
        if (UIData) {
            const cat = UIData.data.category.toLowerCase() === "education" ? 1 : 2;

            if (values.category === cat) {
                setDataInForms();
            }
        }
    }, [values.category, UIData, setDataInForms]);

    return (
        <HomeUiFormStyle className="home-ui-form">
            <Box className="container">
                <ul>
                    <li>
                        <CategorySelection data={category} isSelected={values.category} onSelected={handleSelect} />
                    </li>
                    <li>
                        <TextField
                            fullWidth
                            className="name"
                            label="Name"
                            value={values.name}
                            onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))}
                        />
                    </li>
                    <li>
                        <Button variant="contained" onClick={toggleDrawer(true)}>
                            Add UI Section
                        </Button>
                        <IconButton onClick={handleHelp}>
                            <HelpIcon />
                        </IconButton>
                    </li>
                </ul>

                <Sections />

                <br />
                <Button fullWidth variant="contained" onClick={handleSubmitUI}>
                    {UI_ID ? "Update this UI" : "Create this UI"}
                </Button>

                <Dialog open={open} onClose={toggleDrawer(false)}>
                    <DialogContent>
                        <Typography className="title">
                            <span>{currentSection ? "Update" : "Create"} Section for </span>
                            <b>{values.category === 2 ? "Entertainment" : "Education"}</b>
                        </Typography>
                        <UISectionForm isEntertainment={values.category === 2} onSubmit={handleSubmitSection} />
                    </DialogContent>
                </Dialog>

                <Dialog open={helpStatus} onClose={handleCloseHelpDialog}>
                    <DialogTitle>Help</DialogTitle>
                    <DialogContent>
                        <Typography style={{ marginBottom: "10px", fontSize: "13px" }}>
                            In creating sections please follow these rules to have a nice clean home page.
                            <br /> You can only see the Final UI after create the UI.
                            <br />
                            <br /> Remember in order to Active the created UI you should do it from the Home UI table .
                        </Typography>
                        <Alert severity="info" style={{ marginBottom: "5px" }}>
                            Spotlight must be the first item in the list
                        </Alert>
                        <Alert severity="info" style={{ marginBottom: "5px" }}>
                            You must have one and only one Spotlight in the list.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseHelpDialog}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </HomeUiFormStyle>
    );
}
