import AxiosInstance from "@/api";
import {
    IGetUIListResponse,
    IUIDeleteResponse,
    IGetUIsBody,
    IGetUIResponse,
    IUIUpdateBody,
    IUICreateBody,
    IGetHomeDesginResponse,
} from "@/api/types/home-ui";
import { API_ROUTES } from "@/constants/api-routes";
import { serializeObject } from "@/utils/serializer";

export const GetUIs = async ({ params }: { params: IGetUIsBody }) => {
    const result = await AxiosInstance.get<IGetUIListResponse>(`${API_ROUTES.HOME.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const GetUIById = async (id: number) => {
    const result = await AxiosInstance.get<IGetUIResponse>(API_ROUTES.HOME.SHOW(id));

    return result.data;
};

export const GetHomeDesgin = async (id: number) => {
    const result = await AxiosInstance.get<IGetHomeDesginResponse>(API_ROUTES.HOME.DESGIN(id));

    return result.data;
};

export const DeleteUI = async (id: number) => {
    const result = await AxiosInstance.delete<IUIDeleteResponse>(API_ROUTES.HOME.DELETE(id));

    return result.data;
};

export const UpdateUI = async (id: number, body: Partial<Omit<IUIUpdateBody, "id">>) => {
    const result = await AxiosInstance.put<IGetUIResponse>(API_ROUTES.HOME.UPDATE(id), body);

    return result.data;
};

export const CreateUI = async (body: Omit<IUICreateBody, "id">) => {
    const result = await AxiosInstance.post<IGetUIResponse>(API_ROUTES.HOME.CREATE, body);

    return result.data;
};
