/* eslint-disable no-unused-vars */
import { Box, Typography } from "@mui/material";

import CategorySelectionStyle from "./style";

export interface ISwitchData {
    value: number;
    label: string;
}

export interface ICategorySelection {
    isSelected: number;
    data: ISwitchData[];
    onSelected: (num: number) => void;
}

export default function CategorySelection({ isSelected, onSelected, data }: ICategorySelection) {
    return (
        <CategorySelectionStyle className={isSelected === data[0].value ? "selected" : "notSelected"}>
            {data.map((item) => {
                return (
                    <Box
                        key={item.label}
                        className={`box ${isSelected === item.value ? "selected" : ""}`}
                        onClick={() => onSelected(item.value)}
                    >
                        <Typography className="text">{item.label}</Typography>
                    </Box>
                );
            })}
        </CategorySelectionStyle>
    );
}
