import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

/* eslint-disable no-unused-vars */
export enum CATEGORY {
    ENTERTAINMENT = "entertainment",
    EDUCATION = "education",
}
export enum UI_SECTION {
    HORIZONTAL = "horizontal",
    SPOTLIGHT = "spotLight",
    CONTINUE = "continue",
    VERTICAL = "vertical",
}

export enum Tag {
    Spotlight = 1,
    LatestMovies = 2,
    PopularMovies = 3,
    LatestSeries = 4,
    FunDocumentariesForLearning = 5,
}

export const tagMapping: { [key in Tag]: string } = {
    [Tag.Spotlight]: "spotlight",
    [Tag.LatestMovies]: "latest-movies",
    [Tag.PopularMovies]: "popular-movies",
    [Tag.LatestSeries]: "latest-series",
    [Tag.FunDocumentariesForLearning]: "fun-documentaries-for-learning",
};

export const SectionColors: { [key in UI_SECTION]: string } = {
    horizontal: "#2a9d8f",
    spotLight: "#e9c46a",
    continue: "#f4a261",
    vertical: "#e76f51",
};

export const SectionIcons: { [key in UI_SECTION]: any } = {
    spotLight: TipsAndUpdatesIcon,
    continue: KeyboardDoubleArrowRightIcon,
    horizontal: ArrowForwardIcon,
    vertical: ArrowDownwardIcon,
};
