import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 65%;
    width: 100%;
    z-index: 0;
    position: relative;
    background: ${({ url }: { url: string }) => `url(${url})`} ;
    background-size: cover;

    &:before {
        content:'';   
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => (theme.palette.mode === "dark" ? "linear-gradient(0deg, #212121 2%, rgba(255,255,255,0) 100%, rgba(0,200,255,.1) 100%)" : "linear-gradient(0deg, #fff 2%, rgba(0,0,0,0) 100%, rgba(255,55,0,.1) 30%)")};
    }

    .info {
        z-index:4;
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;

        .content-type {
            font-weight: 600;
            font-size: 12px;
            line-height: 19.2px;
            letter-spacing: 0px;
        }

        .name {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0px;
        }

        .genres {
            font-weight: 400;
            font-size: 12px;
            line-height: 19.2px;
            letter-spacing: 0px;

            display: flex;
            flex-direction: row;

            span {
                position: relative;
                margin-inline-end: 15px;

                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content:"";
                    position: absolute;
                    right: -10px;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #0F62F3;
                }
            }
        }

        .description {
            font-family: Raleway;
            font-weight: 400;
            font-size: 14px;
            line-height: 22.4px;
            letter-spacing: 0px;
        }
    }

`;
