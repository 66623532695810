import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled as mStyles } from "@mui/material/styles";

export default styled("div")`

  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
      width: 100%;
      height: 100%;
      margin:0 auto;
      padding: 1em;
      display: flex;
      flex-flow: column;
      border-radius: 20px;
      box-sizing: border-box;

      ul {
        margin:0;
        padding:0;
        list-style: none;
        
        li {
          margin-bottom: 1em;

          .MuiFormLabel-root  {
          }
          .MuiInputBase-root  {
          }

        }
      }
  }

`;

export const Puller = mStyles("div")(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
    ...theme.applyStyles("dark", {
        backgroundColor: grey[900],
    }),
}));
