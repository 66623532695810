import { styled } from "@mui/material";

export default styled("div")`
    
    padding: 1em;
    flex: 1; 
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 20px;
    flex: 1 1 auto;
    overflow-y: auto;
    height: 100px;
    border: 1px solid ${({ theme }) => theme.palette.background.input};

    .section-list {
        flex-direction: column;
        display: flex;
    }
`;
