import { Box, Typography } from "@mui/material";

import VertialLiveStyle from "./style";

import { IDesginItem } from "@/api/types/home-ui";
import { CONTENT_TYPE_TEXT } from "@/constants/enums/content-type";

export default function LiveVertial({ data }: { data: IDesginItem[] }) {
    return (
        <VertialLiveStyle>
            <Typography className="title">Latest Series</Typography>
            <Box className="vertical-list">
                {data.map((item) => {
                    const { genres, thumbnailLandscape, ml_name, typeId } = item;

                    return (
                        <Box key={item.id} className="content-tile">
                            <img alt={ml_name?.en} src={thumbnailLandscape?.url} />
                            <Box className="info">
                                <Typography className="content-type">{CONTENT_TYPE_TEXT[typeId.toString()]}</Typography>
                                <Typography className="name">{ml_name?.en}</Typography>
                                <Typography className="genres">
                                    {genres.map((item) => {
                                        return (
                                            <span key={item?.id} className="genre">
                                                {item?.ml_title?.en}
                                            </span>
                                        );
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </VertialLiveStyle>
    );
}
