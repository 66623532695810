import { useEffect, useState } from "react";

import CategorySelection from "../categorySelection";

import PreviewStyle from "./style";
import PhoneContent from "./phoneContent";

import "swiper/css";
import "swiper/css/pagination";

const categories = [
    { label: "Demo UI", value: 1 },
    { label: "Final UI", value: 2 },
];

export default function Preview({ id }: { id?: number }) {
    const [values, setValues] = useState(categories[0]);

    const handleSelect = (num: number) => {
        setValues((prev) => ({ ...prev, value: num }));
    };

    useEffect(() => {
        if (id) {
            setValues(categories[1]);
        }
    }, [id]);

    return (
        <PreviewStyle className="preview">
            <CategorySelection data={categories} isSelected={values.value} onSelected={handleSelect} />
            <PhoneContent id={Number(id)} mode={values.value} />
        </PreviewStyle>
    );
}
