import { useEffect, useState } from "react";

function pad(num: number, size: number) {
    var s = num + "";

    while (s.length < size) s = "0" + s;

    return s;
}

function getClock() {
    const date = new Date();
    const hour = date.getHours();
    const min = date.getMinutes();

    return `${pad(hour, 2)}:${pad(min, 2)}`;
}

export default function Clock() {
    const [time, setTime] = useState(getClock());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getClock());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return <span className="clock">{time}</span>;
}
