import { useEffect } from "react";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { flushSync } from "react-dom";
import { Typography, Box } from "@mui/material";

import SectionItem from "../section";
import { isSectionData } from "../section/section-data";

import SectionsStyle from "./style";

import { useHomeUiActions, useHomeUiSections } from "@/context/home-ui";

export default function Sections() {
    const { setSections } = useHomeUiActions();
    const sections = useHomeUiSections();

    useEffect(() => {
        return monitorForElements({
            canMonitor({ source }) {
                // wich draggabe items should be monitored for dragging, source is an element
                return isSectionData(source.data);
            },
            onDrop({ location, source }) {
                const target = location.current.dropTargets[0];

                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!isSectionData(sourceData) || !isSectionData(targetData)) {
                    return;
                }

                const indexOfSource = sections.findIndex((section) => section.uuid === sourceData.sectionId);
                const indexOfTarget = sections.findIndex((section) => section.uuid === targetData.sectionId);

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                // Using `flushSync` so we can query the DOM straight after this line
                flushSync(() => {
                    setSections(
                        reorderWithEdge({
                            // ! this reordering that handle all edge caeses for us better to use this instead of out reordering
                            list: sections,
                            startIndex: indexOfSource,
                            indexOfTarget,
                            closestEdgeOfTarget,
                            axis: "vertical",
                        })
                    );
                });
                // Being simple and just querying for the task after the drop.
                // We could use react context to register the element in a lookup,
                // and then we could retrieve that element after the drop and use
                // `triggerPostMoveFlash`. But this gets the job done.
                const element = document.querySelector(`[data-task-id="${sourceData.sectionId}"]`);

                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element); // ! this create a flash effect for better UX
                }
            },
        });
    }, [sections, setSections]);

    return (
        <SectionsStyle className="section-list-wrapper">
            {sections.length === 0 && <Typography>Please add a section</Typography>}
            <Box className="section-list">
                {sections.map((section) => {
                    return <SectionItem key={section.uuid} section={section} />;
                })}
            </Box>
        </SectionsStyle>
    );
}
